import React, { useState } from "react";
import cn from "classnames";
import styles from "./Main.module.sass";
import Form from "../../../components/Form";

const Main = () => {
  const [search, setSearch] = useState("");

  const handleSubmit = (e) => {
    alert();
  };

  return (
    <div className={cn("section-mb0", styles.main)}>
      <div className={cn("container", styles.container)}>
        <h1 className={cn("h1", styles.title)}>How can we help</h1>
        <div className={styles.info}>
          Your Trusted Source for Affordable and Reliable Registration Checks in
          Australia
        </div>
      </div>
    </div>
  );
};

export default Main;
