import cn from "classnames";
import styles from "./SearchBar.module.sass";
import Icon from "../Icon";
import SearchDropdown from "./SearchDropdown";
import "../../styles/spacing.module.sass";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

const allStates = [
  //All australian states
  "NSW",
  "QLD",
  "SA",
  "TAS",
  "VIC",
  "WA",
  "ACT",
  "NT",
];

function SearchBar({ className, name }) {
  const [ausState, setAusState] = useState(allStates[0]);
  const [errorText, setErrorText] = useState();
  const [regoInputValue, setRegoInputValue] = useState();
  const navigate = useNavigate();

  function onSubmit(e) {
    e.preventDefault();
    if (regoInputValue.length < 3) {
      console.log("Not valid");
      return;
    }
    console.log(errorText);
    if (errorText == null) {
      navigate(`/regosearch?state=${ausState}&rego=${regoInputValue}`);
    }
  }

  function checkInput(e) {
    e.preventDefault();
    setRegoInputValue(e.target.value.trim());
    if (e.target.value.match(/^[a-zA-Z0-9]*$/)) {
      setErrorText(null);

      if (e.target.value === "" || e.target.value.length < 3) {
      } else {
      }
    } else {
      setErrorText("Please enter a valid rego number");
    }
  }

  return (
    <div>
      {errorText && <p>{errorText}</p>}
      <form
        className={cn(className, styles.card, styles.form)}
        action=""
        name={name}
        onSubmit={onSubmit}
        onError={(e) => {
          console.log("error");
        }}
      >
        <div className={styles.searchbar}>
          <input
            maxLength="10"
            autoFocus
            className={styles.input}
            type="text"
            onChange={checkInput}
            placeholder={"Enter Rego Number"}
            required
            onError={(e) => {
              console.log("error");
            }}
          />
          <SearchDropdown
            className={styles.dropdown}
            value={ausState}
            setValue={setAusState}
            options={allStates}
          />
        </div>
        <div>
          <button className={styles.btn}>
            <span>Search</span>
            <FaArrowRight style={{ marginLeft: "12px" }} />
          </button>
        </div>
      </form>
    </div>
  );
}

export default SearchBar;
