import React from "react";
import cn from "classnames";
import styles from "./PageNotFound.module.sass";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <>
      <div className={cn("section-mb0", styles.main)}>
        <div className={cn("container", styles.container)}>
          <h1 className={cn("h1", styles.title)}>Page Not Found</h1>
          <div className={styles.info}>
            Please check the URL in the address bar and try again. Visit our
            home page to find out more about our services.
          </div>
          <Link className={cn("button", styles.button)} to="/">
            Vist Home Page
          </Link>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
