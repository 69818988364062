import React from "react";
import cn from "classnames";
import styles from "./PriceHistory.module.sass";
import { Link } from "react-router-dom";
import Icon from "../../../components/Icon";

function PriceHistory({ regoComprehensiveData }) {
  function formatString(inputString) {
    const characters = inputString.split("");
    const firstCapitalIndex = characters.findIndex(
      (char) => char === char.toUpperCase()
    );

    // If a capital letter is found, insert a space before it
    if (firstCapitalIndex !== -1) {
      characters.splice(firstCapitalIndex, 0, " ");
    }

    // Join the characters back into a string
    const formattedBodyType = characters.join("");

    // Capitalize the first letter of the formatted string
    const capitalizedBodyType =
      formattedBodyType.charAt(0).toUpperCase() + formattedBodyType.slice(1);

    return capitalizedBodyType;
  }

  function buildPriceHistory() {
    var itemList = [];
    if (!regoComprehensiveData || !regoComprehensiveData.price) {
      return <p>No price information available</p>;
    } else {
      var priceObject = regoComprehensiveData.price;
      var index = 0;
      for (const key in priceObject) {
        if (priceObject[key] && typeof priceObject[key] === "string") {
          itemList.push(
            <div className={styles.line} key={key + "_" + index}>
              <div className={styles.subtitle}>{formatString(key)}</div>
              <div className={styles.details}>
                <div className={styles.content}>{priceObject[key]}</div>
              </div>
            </div>
          );
        } else if (priceObject[key] && typeof priceObject[key] === "object") {
          var currObject = priceObject[key];
          var currString = "";
          for (var obj in currObject) {
            currString += formatString(obj) + ": $" + currObject[obj] + " ";
          }

          itemList.push(
            <div className={styles.line} key={key + "_" + index + "_" + index}>
              <div className={styles.subtitle}>{formatString(key)}</div>
              <div className={styles.details}>
                <div className={styles.content}>{formatString(currString)}</div>
              </div>
            </div>
          );
        }
        index++;
      }
    }

    return itemList;
  }
  return (
    <div
      style={{ marginTop: "40px", marginBottom: "40px" }}
      className={styles.actions}
    >
      <div className={styles.info}>Car Price information ($)</div>
      {buildPriceHistory()}
    </div>
  );
}

export default PriceHistory;
