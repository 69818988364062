import { ToastContainer, toast } from "react-toastify";

class Utils {
  static showToastMessage = () => {
    toast.success("Success Notification !", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  static getUrlPram(paramName) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(paramName);
  }

  static saveReferrer(referrer) {
    if (referrer) {
      localStorage.setItem("referrer", Utils.getUrlPram("r"));
    }
  }

  static getStorageItem(key) {
    return localStorage.getItem(key);
  }
}

export default Utils;
