import React from "react";
import Main from "./Main";
import Education from "./Education";
import Question from "./Question";
import Faq from "../../components/Faq";

const faqItems = [
  {
    title: "How long does it take to receive a registration check report?",
    content:
      "Registration check reports are generated instantly. We provide a comprehesive report upon payment instantly.",
  },
  {
    title: "What information is included in a registration check report?",
    content:
      "A comprehensive registration check report typically includes details such as the vehicle's registration history, stolen status, written-off records, registration details readings, and other relevant information sourced from various databases.",
  },
  {
    title:
      "What should I do if a registration check reveals issues with the vehicle?",
    content:
      "If a registration check report reveals issues or discrepancies with the vehicle, it's recommended to seek professional advice or consult relevant authorities to understand the implications and determine the best course of action.",
  },
  {
    title: "How do you collect the data for the report?",
    content:
      "Written off, stolen, make and model details are provided by state and territory road agencies and police. They do this via a database known as the National Exchange of Vehicle and Driver Information System (NEVDIS). If NEVDIS hasn't been updated or is offline, the data won’t appear on your search certificate. Stolen vehicle information is not available on NEVDIS for Tasmanian vehicles. ",
  },
  {
    title: "Data privacy policy",
    content:
      "We take your privacy seriously. We will only use your personal information to provide you with your report. We will not share your personal information with any third party. We will not use your personal information for marketing purposes. We will only keep data within our servers for 5 days and will be completely deleted after that.",
  },
  {
    title: "Should I perform a registration check on a vehicle I'm selling?",
    content:
      "Certainly, performing a registration check on a vehicle you're selling can be beneficial. It allows you to provide potential buyers with transparency and builds trust in the transaction. It also helps you ensure that the vehicle's registration details are accurate and up to date.",
  },
];

const Home = () => {
  return (
    <>
      <Main />
      <Faq items={faqItems} />
      <Question />
    </>
  );
};

export default Home;
