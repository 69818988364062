import React, { useState } from "react";
import cn from "classnames";
import styles from "./Actions.module.sass";
import { Link } from "react-router-dom";
import Icon from "../../../components/Icon";
import Modal from "../../../components/Modal";
import {
  FaExclamationTriangle,
  FaPencilAlt,
  FaDollarSign,
  FaCarCrash,
  FaCarAlt,
  FaTimesCircle,
  FaHandPaper,
} from "react-icons/fa";

const items = [
  {
    title: "Takata airbag",
    content: "Airbag recall affects millions of vehicles",
    color: "#3772FF",
    image: <FaCarCrash style={{ fontSize: "60px", color: "white" }} />,
    url: "/deposit-fiat",
  },
  {
    title: "Car Theft",
    content: "On avg, a car is stolen every 10 mins in Australia",
    color: "#9757D7",
    image: (
      <FaExclamationTriangle style={{ fontSize: "60px", color: "white" }} />
    ),
  },
  {
    title: "Finance Owing",
    content: "1 in 6 cars have outstanding finance",
    color: "#FF6838",
    image: <FaDollarSign style={{ fontSize: "60px", color: "white" }} />,
  },
  {
    title: "Written Off",
    content: "1 in 16 cars have been recorded as a write off",
    color: "#58BD7D",
    image: <FaHandPaper style={{ fontSize: "60px", color: "white" }} />,
  },
];

const Actions = ({ className }) => {
  const [visibleTransfer, setVisibleTransfer] = useState(false);

  return (
    <>
      <div className={cn(className, styles.actions)}>
        <div className={styles.list}>
          {items.map((x, index) => (
            <div className={styles.item} key={index}>
              <div
                className={styles.preview}
                style={{ backgroundColor: x.color }}
              >
                {x.image}
              </div>
              <div className={styles.details}>
                <div className={styles.title}>{x.title}</div>
                <div className={styles.content}>{x.content}</div>
                {/* {x.url ? (
                  <button
                    className={cn("button-stroke button-small", styles.button)}
                    onClick={() => setVisibleTransfer(true)}
                  >
                    <span>Learn More</span>
                    <Icon name="arrow-next" size="10" />
                  </button>
                ) : (
                  <></>
                )} */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Actions;
