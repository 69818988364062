export class RegoComprehensive {
  constructor(
    bodyType,
    summary,
    engineCC,
    color,
    year,
    engineType,
    nvic,
    features,
    transmission,
    fuelType,
    regoState,
    series,
    price,
    variant,
    registration,
    model,
    cylinder,
    regoNumber,
    engineLitre,
    make,
    govRegoLink
  ) {
    this.bodyType = bodyType;
    this.summary = summary;
    this.engineCC = engineCC;
    this.color = color;
    this.year = year;
    this.engineType = engineType;
    this.nvic = nvic;
    this.features = features;
    this.transmission = transmission;
    this.fuelType = fuelType;
    this.regoState = regoState;
    this.series = series;
    this.price = price;
    this.variant = variant;
    this.registration = registration;
    this.model = model;
    this.cylinder = cylinder;
    this.regoNumber = regoNumber;
    this.engineLitre = engineLitre;
    this.make = make;
    this.govRegoLink = govRegoLink;
  }

  static fromJSON(json) {
    const {
      bodyType,
      summary,
      engineCC,
      color,
      year,
      engineType,
      nvic,
      features,
      transmission,
      fuelType,
      regoState,
      series,
      price,
      variant,
      registration,
      model,
      cylinder,
      regoNumber,
      engineLitre,
      make,
      govRegoLink,
    } = JSON.parse(json);

    return new RegoComprehensive(
      bodyType,
      summary,
      engineCC,
      color,
      year,
      engineType,
      nvic,
      features,
      transmission,
      fuelType,
      regoState,
      series,
      price,
      variant,
      registration,
      model,
      cylinder,
      regoNumber,
      engineLitre,
      make,
      govRegoLink
    );
  }
}
