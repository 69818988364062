import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import styles from "./Footer.module.sass";
import Form from "../Form";
import Icon from "../Icon";
import Image from "../Image";

const menu = [
  {
    title: "Exchange",
    url: "/exchange",
  },
  {
    title: "Buy crypto",
    url: "/buy-crypto",
  },
  {
    title: "Market",
    url: "/market",
  },
  {
    title: "Learn crypto",
    url: "/learn-crypto",
  },
  {
    title: "Contact",
    url: "/contact",
  },
];

const socials = [
  {
    title: "twitter",
    size: "18",
    url: "https://twitter.com/mycarrego",
  },
];

const Footer = () => {
  const [visible, setVisible] = useState(false);
  const [email, setEmail] = useState("");
  const { pathname } = useLocation();

  const handleSubmit = (e) => {
    alert();
  };

  return (
    <footer className={styles.footer}>
      {/* <div className={styles.body}>
        <div className={cn("container", styles.container)}>
          <div className={styles.col}>
            <Link className={styles.logo} to="/">
              <img
                className={styles.picDesktop}
                src="images/logo.png"
                alt="My Car Rego"
              />
              <Image
                className={styles.picMobile}
                src="/images/logo.png"
                alt="My Car Rego"
              />
            </Link>
          </div>
          <div className={styles.col}>
            <NavLink to={"/contact"}>
              <div className={styles.category}>Contact Us</div>
            </NavLink>
          </div>
          <div className={styles.col}>
            <div className={styles.category}>newsletter</div>
            <div className={styles.info}>
              Subscribe our newsletter to get more free design course and
              resource.
            </div>
            <Form
              className={styles.form}
              value={email}
              setValue={setEmail}
              onSubmit={() => handleSubmit()}
              placeholder="Enter your email"
              type="email"
              name="email"
              icon="arrow-next"
            />
          </div>
        </div>
      </div> */}
      <div className={styles.foot}>
        <div className={cn("container", styles.container)}>
          <div className={styles.copyright}>
            Copyright © 2023 My Car Rego. All rights reserved
            <br />
            <br />
          </div>

          {/* <div className={styles.socials}>
            {socials.map((x, index) => (
              <a
                className={styles.social}
                href={x.url}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
              >
                <Icon name={x.title} size={x.size} />
              </a>
            ))}
          </div> */}
        </div>
        <div
          className={styles.socials}
          style={{ justifyContent: "center", marginBottom: "20px" }}
        >
          {socials.map((x, index) => (
            <a
              className={styles.social}
              href={x.url}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
            >
              <Icon name={x.title} size={x.size} />
            </a>
          ))}
        </div>
        <NavLink className={cn(styles.link)} to="/termsandconditions">
          Terms & Conditions
        </NavLink>
        <NavLink className={cn(styles.link)} to="/termsandconditions">
          Contact Us
        </NavLink>

        <div
          className={styles.copyright}
          style={{ maxWidth: "1000px", padding: "8px" }}
        >
          <br />
          Disclaimer: The information provided on this website is general in
          nature and we provide no undertakings or warranties concerning the
          accuracy, completeness or up-to-date nature of the information
          provided. We recommend you obtain your own independent advice.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
