import React, { useState } from "react";
import cn from "classnames";
import styles from "./Faq.module.sass";
import Item from "./Item";

function Faq({ items }) {
  return (
    <div
      className={cn("container", styles.container)}
      style={{ marginBottom: "80px", marginTop: "80px" }}
    >
      <div className={styles.top}>
        <h2 className={cn("h3", styles.title)}>Frequently asked questions</h2>
      </div>
      <div className={styles.list}>
        {items.map((x, index) => (
          <Item className={styles.item} item={x} index={index} key={index} />
        ))}
      </div>
    </div>
  );
}

export default Faq;
