import React, { useRef } from "react";
import SearchBar from "../../components/SearchBar";
import Actions from "./Actions";
import Main from "./Main";
import Steps from "./Steps";
import Faq from "../../components/Faq";
import styles from "./Home.module.sass";
import cn from "classnames";
// import News from "./News";
import Download from "./Download";

const faqItems = [
  {
    title: "How long does it take to receive a registration check report?",
    content:
      "Registration check reports are generated instantly. We provide a comprehesive report upon payment instantly.",
  },
  {
    title: "What information is included in a registration check report?",
    content:
      "A comprehensive registration check report typically includes details such as the vehicle's registration history, stolen status, written-off records, registration details readings, and other relevant information sourced from various databases.",
  },

  {
    title: "Should I perform a registration check on a vehicle I'm selling?",
    content:
      "Certainly, performing a registration check on a vehicle you're selling can be beneficial. It allows you to provide potential buyers with transparency and builds trust in the transaction. It also helps you ensure that the vehicle's registration details are accurate and up to date.",
  },
];

const NSW = () => {
  const scrollToRef = useRef(null);

  return (
    <>
      <Main scrollToRef={scrollToRef} />
      <div className={cn("container", styles.container)}>
        <Actions scrollToRef={scrollToRef} />
      </div>
      {/* <News classSection="section-bg" /> */}
      <Download />
      <Steps />
      <Faq items={faqItems} />

      <div className={styles.sectionfull}>
        <h2 className={cn("h2", styles.title)}>Make Informed Car Decisions</h2>
        <div className={styles.info}>
          Get peace of mind with a comprehensive car report
        </div>
        <SearchBar className={styles.marginHorizontalAuto} />
      </div>
    </>
  );
};

export default NSW;
