import React from "react";
import cn from "classnames";
import styles from "./TermsAndConditions.module.sass";

const TermsAndConditions = () => {
  return (
    <div className={cn("section", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.content} style={{ marginTop: "40px" }}>
          <h3>Terms and Conditions</h3>
          <p>
            Use of the Site <br />
            a. You must be at least 18 years old to use the Site. <br />
            b. You agree to use the Site for lawful purposes and in compliance
            with all applicable laws
          </p>
          <p>
            Privacy Policy <br />
            a. Our Privacy Policy describes how we collect, use, and protect
            your personal information when you use the Site. By using the Site,
            you consent to our collection, use, and disclosure of your personal
            information as described in the Privacy Policy.
          </p>
          <p>
            Disclaimer of Warranty and Limitation of Liability
            <br />
            a. The information and services provided on the Site are for general
            informational purposes only. We do not warrant the accuracy,
            completeness, or reliability of the information.
            <br />
            b. We are not responsible for any errors or omissions on the Site or
            any damages resulting from your use of the Site or reliance on the
            information provided.
            <br />
            c. To the extent permitted by law, we disclaim all warranties,
            express or implied, including warranties of merchantability, fitness
            for a particular purpose, and non-infringement.
            <br />
            d. In no event shall My Car Rego be liable for any direct, indirect,
            incidental, special, or consequential damages arising out of or in
            any way connected with your use of the Site or the information
            provided.
          </p>

          <p>
            Third-Party Links <br />
            a. The Site may contain links to third-party websites or services
            that are not owned or controlled by My Car Rego. We have no control
            over and assume no responsibility for the content, privacy policies,
            or practices of any third-party websites or services. You access and
            use such websites or services at your own risk.
          </p>

          <p>
            If you have any questions, please contact us at
            info@mycarrego.com.au
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
