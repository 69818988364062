import React from "react";
import cn from "classnames";
import styles from "./FoundRegoData.module.sass";
import TextInput from "../../../components/TextInput";
import CheckListItem from "../../../components/CheckListItem";
import Icon from "../../../components/Icon";
import { FaCheckCircle } from "react-icons/fa";
import {
  PaymentElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import GoogleApplePayButton from "../../../components/GoogleApplePayButton";
import CheckoutForm from "../../../components/CheckoutForm";
import Faq from "../../../components/Faq/";
import Divider from "../../../components/Divider";
function FoundRegoData({
  regoSummaryObj,
  stripePromise,
  clientSecret,
  price,
  flowuid,
}) {
  function buildCheckItem(name) {
    return (
      <div className={styles.elementcheck} style={{ marginRight: "16px" }}>
        <FaCheckCircle className={styles.checkicon} />
        {name}
      </div>
    );
  }

  return (
    <div>
      <div className={styles.card} style={{ marginBottom: "0px !important" }}>
        <div className={styles.stage}>Comprehensive report available</div>
        <div className={cn("h3", styles.title)}>
          Found data for <span>{regoSummaryObj.regoNumber}</span> in
          <span style={{ marginLeft: "8px" }}>{regoSummaryObj.state}</span>
        </div>
        <div className={styles.row}>
          <div className={styles.element} style={{ marginRight: "16px" }}>
            <span style={{ marginRight: "6px" }}>Make: </span>
            {regoSummaryObj.make}
          </div>
          <div className={styles.element} style={{ marginRight: "16px" }}>
            <span style={{ marginRight: "6px" }}>Model: </span>
            {regoSummaryObj.model}
          </div>
          <div
            className={styles.element}
            style={{ marginRight: "16px", borderRight: "0px" }}
          >
            <span style={{ marginRight: "6px" }}>Year: </span>
            {regoSummaryObj.year}
          </div>
        </div>
      </div>
      <div className={styles.card} style={{ marginTop: "0px !important" }}>
        <div className={styles.list}>
          <div className={styles.subtitle} style={{ marginBottom: "0px" }}>
            Comprehensive Car Report
          </div>
          <div className={styles.info}>
            Get peace of mind with a comprehensive car report
          </div>
          <div className={styles.rowwrap}>
            <CheckListItem name={"Stolen check"} />
            <CheckListItem name={"PPSR Certificate"} />
            <CheckListItem name={"VIN Check"} />
            <CheckListItem name={"Vehicle valuation"} />
            <CheckListItem name={"Registration details"} />
            <CheckListItem name={"Takata airbag recall"} />
            <CheckListItem name={"Written-off check"} />
          </div>
          <Divider />
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              marginBottom: "30px",
              marginTop: "30px",
            }}
          >
            <div className={styles.payment}>
              <div
                className={styles.label}
                style={{
                  width: "100%",
                }}
              >
                Amount
              </div>
              <div className={styles.field} style={{ display: "flex" }}>
                <div className={cn("h4", styles.sign)}>$</div>
                <div className={styles.input}>{price / 100}</div>
              </div>
              <div className={styles.infodesktop}>
                Every <b>My Car Rego</b> Report comes with an PPSR (Personal
                Property Securities Register) certificate. We provide a copy of
                the official PPSR certificate to you.
              </div>
            </div>

            <img
              src={"/images/ppsr.svg"}
              alt="Education"
              className={styles.ppsrimage}
            />
          </div>
          <div className={styles.infomobile}>
            Every <b>My Car Rego</b> Report comes with an PPSR (Personal
            Property Securities Register) certificate. We provide a copy of the
            official PPSR certificate to you.
          </div>

          <div className={styles.item}>
            {stripePromise && clientSecret && (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <GoogleApplePayButton
                  flowuid={flowuid}
                  clientSecret={clientSecret}
                  price={price}
                />
              </Elements>
            )}
          </div>
          <div className={styles.item}>
            {stripePromise && clientSecret && (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <CheckoutForm flowuid={flowuid} price={price} />
              </Elements>
            )}
          </div>
          <img
            src={"/images/pricematch2.webp"}
            alt="Price Match"
            style={{ width: "100%", borderRadius: "8px", marginTop: "40px" }}
          />
        </div>
      </div>
      {/* <Faq /> */}
    </div>
  );
}

export default FoundRegoData;
