import React from "react";
import cn from "classnames";
import styles from "./NotFound.module.sass";
import TextInput from "../../../components/TextInput";
import Icon from "../../../components/Icon";
import { Link, NavLink, useLocation } from "react-router-dom";

function NotFound({ state, regoNumber }) {
  return (
    <div>
      <div className={styles.card}>
        <div className={styles.stage}>Comprehensive report unavailable</div>
        <div className={cn("h3", styles.title)}>
          Data not found for <span>{regoNumber}</span> in
          <span style={{ marginLeft: "8px" }}>{state}</span>
        </div>
        <div className={styles.email} style={{ marginBottom: "16px" }}>
          Please make sure you entered the correct registration number and state
        </div>
        <NavLink className={cn(styles.link)} to="/">
          Restart Search
        </NavLink>
      </div>
    </div>
  );
}

export default NotFound;
