import React, { useState } from "react";
import cn from "classnames";
import styles from "./CheckListItem.module.sass";
import { FaCheckCircle } from "react-icons/fa";

const CheckListItem = ({ className, name, borderRight = true }) => {
  const [, setVisibleTransfer] = useState(false);

  return (
    <div
      className={cn(
        styles.borderRight,
        className,
        { borderRight },
        styles.elementcheck
      )}
      style={{ marginRight: "16px" }}
    >
      <FaCheckCircle className={styles.checkicon} />
      {name}
    </div>
  );
};

export default CheckListItem;
