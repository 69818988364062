import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Download.module.sass";

const items = [
  {
    title: "NSW",
    content: "New South Wales",
    image: "/states/NSW.png",
    url: "/",
  },
  {
    title: "VIC",
    content: "Victoria",
    image: "/states/VIC.png",
    url: "/",
  },
  {
    title: "QLD",
    content: "Queensland",
    image: "/states/QLD.png",
    url: "/",
  },
  {
    title: "WA",
    content: "Western Australia",
    image: "/states/WA.png",
    url: "/",
  },
  {
    title: "SA",
    content: "South Australia",
    image: "/states/SA.png",
    url: "/",
  },
  {
    title: "TAS",
    content: "Tasmania",
    image: "/states/TAS.png",
    url: "/",
  },
  {
    title: "ACT",
    content: "Australian Capital Territory",
    image: "/states/ACT.png",
    url: "/",
  },
];

const Download = () => {
  return (
    <div className={cn("section-mb0", styles.download)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.bg}>
          <div className={styles.list}>
            {items.map((x, index) => (
              <div className={styles.item} key={index} to={x.url}>
                <div className={styles.icon}>
                  <img src={x.image} alt="Logo" />
                </div>
                <div className={styles.details}>
                  <div className={styles.content}>{x.content}</div>
                  <div className={styles.subtitle}>{x.title}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.wrap}>
          <h2 className={cn("h2", styles.title)}>What We Offer</h2>
          <div className={styles.info}>
            We provide Aussies with the most comprehensive rego report on the
            market. Without adequate knowledge of your car's registration
            history, you could be putting yourself at risk legally and
            financially.
          </div>

          <div className={styles.list}>
            {items.map((x, index) => (
              <div className={styles.item} key={index} to={x.url}>
                <div className={styles.icon}>
                  <img src={x.image} alt="Logo" />
                </div>
                <div className={styles.details}>
                  <div className={styles.content}>{x.content}</div>
                  <div className={styles.subtitle}>{x.title}</div>
                </div>
              </div>
            ))}
          </div>
          <Link className={cn("button", styles.button)} to="/sign-up">
            View Sample Report
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Download;
