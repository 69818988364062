import React, { useState, useEffect } from "react";
import {
  PaymentRequestButtonElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import styles from "./GoogleApplePayButton.module.sass";
import Divider from "../Divider";
import { useNavigate } from "react-router-dom";

export default function GoogleApplePayButton({ flowuid, clientSecret, price }) {
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (stripe) {
      // var options = {
      //   country: "AU",
      //   currency: "aud",
      //   total: {
      //     label: "Total",
      //     amount: price,
      //   },
      //   requestPayerName: true,
      //   requestPayerEmail: true,
      //   style: {
      //     paymentRequestButton: {
      //       type: "default",
      //       theme: "dark",
      //       height: "64px",
      //     },
      //   },
      // };

      console.log("clientSecret", clientSecret);
      console.log("price", price);
      const pr = stripe.paymentRequest({
        country: "AU",
        currency: "aud",
        total: {
          label: "Rego Report",
          amount: price,
        },
        style: {
          paymentRequestButton: {
            type: "default",
            theme: "dark",
            height: "64px",
          },
        },
      });

      console.log("pr", pr);

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          console.log("result", result);
          setPaymentRequest(pr);

          pr.on("paymentmethod", async (ev) => {
            // Confirm the PaymentIntent without handling potential next actions (yet).
            const { paymentIntent, error: confirmError } =
              await stripe.confirmCardPayment(
                clientSecret,
                { payment_method: ev.paymentMethod.id },
                { handleActions: false }
              );

            if (confirmError) {
              // Report to the browser that the payment failed, prompting it to
              // re-show the payment interface, or show an error message and close
              // the payment interface.
              ev.complete("fail");
              console.log("payment failed");
            } else {
              // Report to the browser that the confirmation was successful, prompting
              // it to close the browser payment method collection interface.
              ev.complete("success");
              // Check if the PaymentIntent requires any actions and, if so, let Stripe.js
              // handle the flow. If using an API version older than "2019-02-11"
              // instead check for: `paymentIntent.status === "requires_source_action"`.
              if (paymentIntent.status === "requires_action") {
                // Let Stripe.js handle the rest of the payment flow.
                const { error } = await stripe.confirmCardPayment(clientSecret);
                if (error) {
                  // The payment failed -- ask your customer for a new payment method.
                  console.log("payment failed");
                } else {
                  // The payment has succeeded.
                  console.log("payment succeeded");
                  navigate(`/report?flowuid=${flowuid}`);
                }
              } else {
                // The payment has succeeded.
                console.log("payment succeeded");
                navigate(`/report?flowuid=${flowuid}`);
              }
            }
          });
        }
      });
    }
  }, [stripe]);

  if (paymentRequest) {
    return (
      <>
        <PaymentRequestButtonElement options={{ paymentRequest }} />
        <Divider label="or" />
      </>
    );
  } else {
    return <></>;
  }
}
