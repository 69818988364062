import React from "react";
import cn from "classnames";
import styles from "./TakataRecall.module.sass";
import { Link } from "react-router-dom";
import Icon from "../../../components/Icon";

function TakataRecall({ className }) {
  return (
    <div style={{ marginTop: "28px" }} className={styles.actions}>
      <div className={styles.info}>Takata Recall</div>
      <div className={styles.text}>
        Tens of millions of vehicles with Takata air bags are under recall.
        Long-term exposure to high heat and humidity can cause these air bags to
        explode when deployed.
      </div>
      <Link target="_blank" to="https://www.fcai.com.au/is-my-airbag-safe">
        <div className={styles.code}>Check vehicle Takata recall</div>
      </Link>
    </div>
  );
}

export default TakataRecall;
