import React, { useState } from "react";
import cn from "classnames";
import styles from "./Question.module.sass";
import Icon from "../../../components/Icon";
import Dropdown from "../../../components/Dropdown";
import TextInput from "../../../components/TextInput";
import TextArea from "../../../components/TextArea";
import Loader from "../../../components/Loader";
import LoadingButton from "../../../components/LoadingButton";
import { getFunctions, httpsCallable } from "firebase/functions";
import { async } from "q";
import Utils from "../../../utils/utils";
import { firebaseFunctions } from "../../../utils/firebase_utils";
import Network from "../../../utils/network";
import StatusBanner from "../../../components/StatusBanner";

const optionsCategory = [
  //contact us categories
  "General Inquiry",
  "Sales",
  "Technical Support",
  "Feedback",
  "Other",
];

function Question() {
  const [category, setCategory] = useState(optionsCategory[0]);
  const [topic, setTopic] = useState(true);
  const [isLoading, setIsloading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  async function sendToSlack(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    setIsloading(!isLoading);

    var channelText = "Form submission\n";
    channelText += "Category: " + category + "\n";
    channelText += "Email: " + formData.get("email") + "\n";
    channelText += "Subject: " + formData.get("subject") + "\n";
    channelText += "Message: " + formData.get("message");

    var data = {
      channel: "rego-contact-us",
      text: channelText,
    };
    Network.makeCall({ endpoint: "slack-send", data: data }).then(
      (response) => {
        console.log("response " + JSON.stringify(response));
        if (response.success) {
          setFormSubmitted(true);
          setIsloading(false);
        }
      }
    );
  }
  return (
    <div className={cn("section-bg section-mb0", styles.section)}>
      <div className={cn("container", styles.container)}>
        <form className={styles.form} action="" onSubmit={sendToSlack}>
          <div className={styles.fieldset}>
            <div className={styles.field}>
              <Dropdown
                className={styles.dropdown}
                label="Topic"
                value={category}
                setValue={setCategory}
                options={optionsCategory}
              />
            </div>
            <TextInput
              className={styles.field}
              label="Email address"
              name="email"
              type="email"
              required
            />
            <TextInput
              className={styles.field}
              label="Subject"
              name="subject"
              type="text"
              required
            />
            <TextArea
              className={styles.field}
              label="Message"
              name="message"
              placeholder="Say something"
              required
            />
          </div>
          {formSubmitted ? (
            <StatusBanner
              statusType={"success"}
              text="We will get back to you within 24 hours!"
            />
          ) : (
            <LoadingButton label={"Submit"} isLoading={isLoading} />
          )}
        </form>
      </div>
    </div>
  );
}

export default Question;
