import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Main.module.sass";
import ScrollButton from "../../../components/ScrollButton";
import SearchBar from "../../../components/SearchBar";
import CheckListItem from "../../../components/CheckListItem";

const Main = ({ scrollToRef }) => {
  return (
    <div className={cn("section", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <h1 className={cn("h1", styles.title)}>
            Don’t <span>risk buying</span> a car with an{" "}
            <span>unknown past</span>
          </h1>
          <div className={styles.text}>
            With our comprehensive registration check, you can confidently make
            informed decisions and safeguard your car purchase
          </div>
          <div className={styles.bg}>
            <CheckListItem className={styles.fontsize} name={"Stolen check"} />
            <CheckListItem
              className={styles.fontsize}
              name={"PPSR Certificate"}
            />
            <CheckListItem
              className={styles.fontsize}
              name={"Written-off check"}
            />
            <CheckListItem className={styles.fontsize} name={"VIN Check"} />
            <CheckListItem
              className={styles.fontsize}
              name={"Vehicle valuation"}
            />
            <CheckListItem
              className={styles.fontsize}
              name={"Registration details"}
            />
            <CheckListItem
              className={styles.fontsize}
              name={"Takata airbag recall"}
            />
          </div>
          <SearchBar className={styles.form} name="search" />
          <ScrollButton
            styles={{ marginTop: "20px !important" }}
            className={styles.scroll}
          />
        </div>
      </div>
    </div>
  );
};

export default Main;
