export class RegoSummary {
  constructor({ state, regoNumber, make, model, year }) {
    this.state = state;
    this.regoNumber = regoNumber;
    this.make = make;
    this.model = model;
    this.year = year;
  }

  static fromJSON(json) {
    const { state, regoNumber, make, model, year } = JSON.parse(json);
    return new RegoSummary({
      state,
      regoNumber,
      make,
      model,
      year,
    });
  }
}
