import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "./firebase_utils";

class Network {
  // write a static method to get the data from the server

  static async makeCall({ endpoint, data }) {
    let errorResponse = {
      userError: "Hmm something went wrong, please try again",
      success: false,
    };

    try {
      const firebaseCall = httpsCallable(firebaseFunctions, endpoint);
      var response = await firebaseCall(data);
      return response.data;
    } catch (error) {
      console.log(
        "Error in making call to" +
          endpoint +
          " with data " +
          JSON.stringify(data)
      );
      return errorResponse;
    }
  }
}

export default Network;
