import React from "react";
import cn from "classnames";
import styles from "./Steps.module.sass";
const items = [
  {
    title: "Enter Rego Number",
    image2x: "/images/content/step-1@2x.png",
    image: "/images/content/step-1@2x.png",
    content:
      "Enter your vehicle registration number and state of registration.",
  },
  {
    title: "Confirm Vehicle Details",
    image2x: "/images/content/convertible.png",
    image: "/images/content/convertible.png",
    content:
      "Validate your registration details of your vehicle, including vehicle type, color, make, and model.",
  },
  {
    title: "Complete Payment & Get Report",
    image2x: "/images/content/cards.png",
    image: "/images/content/cards.png",
    content: "Complete your payment to access the full report of your vehicle.",
  },
];

const Steps = ({ scrollToRef }) => {
  return (
    <div
      className={cn("section", styles.section)}
      ref={scrollToRef}
      id="how-it-works"
    >
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h2", styles.title)}>How it works</h2>
          <div className={styles.info}>
            Process of obtaining a comprehensive car report with My Car Rego
          </div>
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <div className={styles.item} key={index}>
              <div className={styles.preview}>
                <img
                  srcSet={`${x.image2x} 2x`}
                  src={x.image}
                  alt={`Step ${index + 1}`}
                />
              </div>
              <div className={styles.number}>Step {index + 1}</div>
              <div className={styles.subtitle}>{x.title}</div>
              <div className={styles.content}>{x.content}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Steps;
