import React from "react";
import cn from "classnames";
import styles from "./FurtherInformation.module.sass";
import { Link } from "react-router-dom";

function FurtherInformation({ govRegoLink, state }) {
  return (
    <div style={{ marginTop: "28px" }} className={styles.actions}>
      <div className={styles.info}>Further Information</div>
      <div className={styles.text}>
        For more information about your vehicle, visit your state registration
        local authority.
      </div>
      <Link target="_blank" to={govRegoLink}>
        <div className={styles.code}>
          {state} vehicle registration authority
        </div>
      </Link>
    </div>
  );
}

export default FurtherInformation;
