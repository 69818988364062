export class Payment {
  constructor({
    amount,
    currency,
    prettyAmount,
    publishableKey,
    clientSecret,
  }) {
    this.amount = amount;
    this.currency = currency;
    this.prettyAmount = prettyAmount;
    this.publishableKey = publishableKey;
    this.clientSecret = clientSecret;
  }

  static fromJSON(json) {
    const { amount, currency, prettyAmount, publishableKey, clientSecret } =
      JSON.parse(json);
    return new Payment({
      amount,
      currency,
      prettyAmount,
      publishableKey,
      clientSecret,
    });
  }
}
