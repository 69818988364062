import React, { useEffect, setState } from "react";
import cn from "classnames";
import styles from "./LoadingState.module.sass";
import Icon from "../../../components/Icon";
import Loader from "../../../components/Loader";
import { FaCheckCircle, FaBan } from "react-icons/fa";

function LoadingState({ rego, state, regoFound, loadingList }) {
  // Reg found could be null or true or false
  // if (regoFound == true) {
  //   return <h3>HELLLLOOO</h3>;
  // } else if (regoFound == false) {
  //   return <h3>false</h3>;
  // }

  function buildCheckItem(name, index) {
    if (
      regoFound === true &&
      index <= loadingList.length &&
      loadingList[index] === true
    ) {
      return (
        <div className={styles.elementcheck} style={{ marginRight: "16px" }}>
          <FaCheckCircle className={styles.checkicon} />
          {name}
        </div>
      );
    } else if (
      regoFound === false &&
      index <= loadingList.length &&
      loadingList[index] === false
    ) {
      return (
        <div className={styles.elementcheck} style={{ marginRight: "16px" }}>
          <FaBan className={styles.checkicon} style={{ color: "red" }} />
          {name}
        </div>
      );
    } else {
      return (
        <div className={styles.elementcheck} style={{ marginRight: "16px" }}>
          <button
            disabled
            className={cn("button-stroke button-small", styles.button)}
            style={{ boxShadow: "none" }}
          >
            <div className={cn(styles.loader)}></div>
          </button>
          {name}
        </div>
      );
    }
  }

  return (
    <div className={styles.card}>
      <div className={styles.preview}>
        <img
          srcSet="/images/content/lock@2x.png 2x"
          src="/images/content/lock.png"
          alt="Lock"
        />
        <div
          className={styles.text}
          style={{ marginTop: "18px", marginBottom: "0px" }}
        >
          Gathering data for
        </div>
        <div className={styles.bigtext}>
          <span className={styles.rego}>{rego} </span>
          <span className={styles.smalltext}>in</span>
          <span className={styles.state}>{state}</span>
        </div>
        <div className={styles.text}>Do not refresh or close this page</div>
        <div className={styles.rowwrap}>
          {buildCheckItem("Stolen check", 5)}
          {buildCheckItem("PPSR Certificate", 40)}
          {buildCheckItem("VIN Check", 14)}
          {buildCheckItem("Vehicle valuation", 30)}
          {buildCheckItem("Registration details", 5)}
          {buildCheckItem("Written-off check", 20)}
          {buildCheckItem("Takata airbag recall", 26)}
        </div>
      </div>
    </div>
  );
}

export default LoadingState;
