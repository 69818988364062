import React, { useState } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import { Link, NavLink, useLocation } from "react-router-dom";
import Image from "../Image";
import Dropdown from "./Dropdown";
import Settings from "./Settings";
import Icon from "../Icon";
import Notifications from "./Notifications";
import Theme from "../Theme";
import User from "./User";

const navigation = [
  // {
  //     title: "Exchange",
  //     url: "/exchange",
  // },
  // {
  //     title: "Buy Crypto",
  //     dropdown: [
  //         {
  //             title: "Credit card",
  //             icon: "user",
  //             url: "/buy-crypto",
  //         },
  //         {
  //             title: "Bank deposit",
  //             icon: "image",
  //             url: "/deposit-fiat",
  //         },
  //     ],
  // },
  // {
  //     title: "Blog",
  //     url: "/blog",
  // },
  // {
  //   title: "How it works",
  //   url: "/#how-it-works",
  // },
  {
    title: "Contact Us",
    url: "/contact",
  },
];

const Header = ({ headerWide }) => {
  const [visibleNav, setVisibleNav] = useState(false);
  const { pathname } = useLocation();

  return (
    <header className={cn(styles.header, { [styles.wide]: headerWide })}>
      <div className={cn("container", styles.container)}>
        <Link
          className={styles.logo}
          to="/"
          onClick={() => setVisibleNav(false)}
        >
          <Image
            className={styles.picDesktop}
            src="/images/logo-full-100.png"
            srcDark="/images/logo-full-100-white.png"
            alt="Logo"
          />
          <img className={styles.picMobile} src="/images/logo.png" alt="Logo" />
        </Link>

        <div className={styles.wrapper}>
          <div className={styles.control}>
            <div
              className={cn(styles.wrap, {
                [styles.visible]: visibleNav,
              })}
            >
              <nav className={styles.nav}>
                {navigation.map((x, index) => (
                  <NavLink
                    className={cn(styles.item, {
                      [styles.active]: pathname === x.url,
                    })}
                    onClick={() => setVisibleNav(false)}
                    to={x.url}
                    key={index}
                  >
                    {x.title}
                  </NavLink>
                ))}
              </nav>
            </div>
          </div>
          {/* <div className={styles.btns}>
            <Link
              className={cn("button-small", styles.button)}
              to="/sign-up"
              onClick={() => setVisibleNav(false)}
            >
              Sample Report
            </Link>
          </div> */}
          <Theme className={styles.theme} icon />
          <button
            className={cn(styles.burger, {
              [styles.active]: visibleNav,
            })}
            onClick={() => setVisibleNav(!visibleNav)}
          ></button>
        </div>
      </div>
    </header>
  );
};

export default Header;
