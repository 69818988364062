import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Report.module.sass";
import Icon from "../../components/Icon";
import VehicleDetails from "./VehicleDetails";
import TakataRecall from "./TakataRecall";
import PriceHistory from "./PriceHistory";
import PPRS from "./PPSR";
import FurtherInformation from "./FurtherInformation";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { pdfjs } from "react-pdf";
import Faq from "../../components/Faq";
import Network from "../../utils/network";
import Utils from "../../utils/utils";
import Loader from "../../components/Loader";
import LoadingButton from "../../components/LoadingButton";
import Expiry from "./Expiry";
import { RegoComprehensive } from "../../models/rego_comprehensive";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

function Report() {
  const [govRegoLink, setGovRegoLink] = useState();
  const [regoComprehensiveData, setRegoComprehensiveData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingPPSR, setIsLoadingPPSR] = useState(true);
  const [reportLink, setReportLink] = useState();
  const [expiryEPOC, setExpiryEPOC] = useState();
  const [validResponse, setValidResponse] = useState();
  const [state, setState] = useState();
  const [rego, setRego] = useState();
  const [visible, setVisible] = useState(0);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [errorText, setErrorText] = useState();

  useEffect(() => {
    getUrlParams();
  }, []);

  function getUrlParams() {
    var flowuid = Utils.getUrlPram("flowuid");
    if (flowuid) {
      var state = flowuid.split("_")[1];
      var rego = flowuid.split("_")[0];

      if (state && rego) {
        setState(state);
        setRego(rego);
        getVehicleReport(flowuid);
      } else {
        setErrorText("Invalid URL");
        setIsLoading(false);
        setValidResponse(false);
      }
    } else {
      setErrorText("Invalid URL");
    }
  }
  function getVehicleReport(flowuid) {
    var data = {
      flowuid: flowuid,
    };
    Network.makeCall({
      endpoint: "getVehicleReport",
      data: data,
    }).then((response) => {
      if (response.success === true) {
        const regoData = RegoComprehensive.fromJSON(JSON.stringify(response));
        setRegoComprehensiveData(regoData);
        setExpiryEPOC(response.expiryEPOC);
        setIsLoading(false);
        setGovRegoLink(response.govRegoLink);
        generatePPSRReport(flowuid);
      } else {
        setErrorText(
          "Error in getting vehicle report, please try again later :("
        );
      }
    });
  }

  function generatePPSRReport(flowuid) {
    var data = {
      flowuid: flowuid,
    };
    Network.makeCall({
      endpoint: "generatePPSRReport",
      data: data,
    }).then((response) => {
      if (response.success === true) {
        setReportLink(response.reportLink);
        setIsLoadingPPSR(false);
      }
    });
  }

  if (errorText) {
    return (
      <div style={{ margin: "auto", textAlign: "center" }}>
        <div className={styles.info} style={{ marginTop: "12px" }}>
          {errorText}
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div style={{ margin: "auto", textAlign: "center" }}>
        <LoadingButton isLoading={true} />

        <div className={styles.info} style={{ marginTop: "12px" }}>
          Loading ...
        </div>
      </div>
    );
  }
  return (
    <div className={styles.notifications}>
      <div className={cn("container", styles.container)}>
        <VehicleDetails regoComprehensiveData={regoComprehensiveData} />
        <PriceHistory regoComprehensiveData={regoComprehensiveData} />
        <PPRS isLoading={isLoadingPPSR} reportLink={reportLink} />
        <TakataRecall className={styles.actions} />
        {govRegoLink && state && (
          <FurtherInformation govRegoLink={govRegoLink} state={state} />
        )}
        {expiryEPOC && <Expiry expiryEpoc={expiryEPOC} />}
      </div>
    </div>
  );
}

export default Report;
