import React from "react";
import cn from "classnames";
import styles from "./LoadingButton.module.sass";

function LoadingButton({ onClick, label, isLoading }) {
  if (isLoading) {
    return (
      <button
        disabled
        className={cn("button-stroke button-small", styles.button)}
      >
        <div className={cn(styles.loader)}></div>
      </button>
    );
  } else {
    return (
      <button onClick={onClick} className={cn("button-small", styles.button)}>
        {label}
      </button>
    );
  }
}

export default LoadingButton;
