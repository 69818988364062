import cn from "classnames";
import styles from "./PPRS.module.sass";
import React, { useEffect, useState, PureComponent } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import LoadingButton from "../../../components/LoadingButton";

function PPRS({ reportLink, isLoading }) {
  if (isLoading) {
    return (
      <div style={{ margin: "auto", textAlign: "center" }}>
        <LoadingButton isLoading={true} />
      </div>
    );
  }

  if (!reportLink) {
    return <></>;
  }

  return (
    <div style={{ marginTop: "40px" }} className={styles.actions}>
      <div className={styles.info}>PPSR Certificate</div>
      <div className={styles.text}>
        This certificate will show you if the goods you want to buy are likely
        to be <b>free from debt</b>, <b>written off</b>, <b>stolen</b> or other
        things that could put your investment at risk.
        {/* <Link
          style={{ marginLeft: "6px" }}
          target="_blank"
          to={
            "https://www.ppsr.gov.au/searching/do-used-car-or-vehicle-search/understand-your-car-search-result"
          }
        >
          <b>Learn how to read a PPSR certificate →</b>
        </Link> */}
      </div>

      <iframe className={styles.iframe} src={reportLink}></iframe>
    </div>
  );
}

export default PPRS;
