import { Routes, Route, Navigate } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./screens/Home";
import Blog from "./screens/Blog";
import Report from "./screens/Report";
import Contact from "./screens/Contact";
import TermsAndConditions from "./screens/TermsAndConditions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useState } from "react";
import RegoSearch from "./screens/RegoSearch";
import { useNavigate } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import NSW from "./screens/NSW";
import PageNotFound from "./screens/PageNotFound";

function App() {
  const helmetContext = {};

  const navigate = useNavigate();

  const [count, setCount] = useState(0);
  React.useEffect(() => {
    console.log("storage event");
    if (count === 0) {
      window.addEventListener("storage", () => {});
      setCount(1);
    }
  }, []);

  return (
    <HelmetProvider context={helmetContext}>
      <Routes>
        <Route path="/">
          <Route
            index
            element={
              <Page>
                <Home />
              </Page>
            }
          />
        </Route>
        <Route
          path="blog"
          element={
            <Page>
              <Blog />
            </Page>
          }
        />
        <Route
          path="regosearch"
          element={
            <Page>
              <RegoSearch />
            </Page>
          }
        />
        <Route path="report">
          <Route
            index
            element={
              <Page>
                <Report />
              </Page>
            }
          />
        </Route>
        <Route path="contact">
          <Route
            index
            element={
              <Page>
                <Contact />
              </Page>
            }
          />
        </Route>
        <Route path="termsandconditons">
          <Route
            index
            element={
              <Page>
                <TermsAndConditions />
              </Page>
            }
          />
        </Route>
        <Route path="nsw">
          <Route
            index
            element={
              <Page>
                <NSW />
              </Page>
            }
          />
        </Route>
        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </HelmetProvider>
  );
}

export default App;
