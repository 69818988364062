import React, { useEffect } from "react";
import Main from "./Main";
import Article from "./Article";
import Catalog from "./Catalog";

const Blog = () => {
  useEffect(() => {}, []);

  return (
    <>
      <Main />
      <Article />
      <Catalog />
    </>
  );
};

export default Blog;
