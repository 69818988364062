import { firebase, initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions, httpsCallable } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyDzHBwCqbO0Lzi7e0f6MGqlGV4C5Llc1pc",
  authDomain: "comprehensive-rego-check.firebaseapp.com",
  projectId: "comprehensive-rego-check",
  storageBucket: "comprehensive-rego-check.appspot.com",
  messagingSenderId: "423621658910",
  appId: "1:423621658910:web:119aea4f1da36bd377610f",
  measurementId: "G-C239DZ8T6V",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const functions = getFunctions(app, "australia-southeast1");

export const firebaseFunctions = functions;
