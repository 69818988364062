import React, { useState } from "react";
import styles from "./Divider.module.sass";

function Divider({ label }) {
  if (!label) {
    return <div className={styles.divider} />;
  }
  return <div className={styles.textdivider}>{label}</div>;
}

export default Divider;
