import React from "react";
import cn from "classnames";
import styles from "./Expiry.module.sass";
import { Link, useNavigate } from "react-router-dom";
import Icon from "../../../components/Icon";
import Countdown from "react-countdown";

const Expiry = ({ className, expiryEpoc }) => {
  const navigate = useNavigate();
  function onExpire() {
    // navigate("/");
  }

  const Completionist = () => <span>Report Expired</span>;

  const renderer = ({ hours, minutes, seconds, completed, days }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <div className={styles.text}>
          <span>
            You will not be able to access the data after the expiry date.
            Report will expire in{" "}
            <span className={styles.textbold}>
              {days} days {hours} hours and {minutes} minutes
            </span>
          </span>
        </div>
      );
    }
  };

  return (
    <div style={{ marginTop: "28px" }} className={styles.actions}>
      <div className={styles.info}>Report Expiry</div>
      <div className={styles.text}>
        To ensure you have the most up to date information and to ensure data
        safety, this report will be only avaible for 5 days from the date of
        purchase.
      </div>
      <Countdown
        autoStart={true}
        date={expiryEpoc * 1000}
        onComplete={onExpire}
        renderer={renderer}
      />
    </div>
  );
};

export default Expiry;
