import React, { useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./SearchDropdown.module.sass";
import Icon from "../../Icon";

const SearchDropdown = ({
  className,
  value,
  setValue,
  options,
  classDropdownHead,
  classDropdownArrow,
  classDropdownBody,
  classDropdownOption,
}) => {
  const [visible, setVisible] = useState(false);

  const handleClick = (value) => {
    setValue(value);
    setVisible(false);
  };

  return (
    <div style={{ margin: "auto", flex: 5 }}>
      <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
        <div
          className={cn(styles.dropdown, className, {
            [styles.active]: visible,
          })}
        >
          <div
            className={cn(classDropdownHead, styles.head)}
            onClick={() => setVisible(!visible)}
          >
            <div className={styles.selection}>{value}</div>
            <div className={cn(styles.arrow, classDropdownArrow)}>
              <Icon name="arrow-down" size="24" />
            </div>
          </div>
          <div className={cn(classDropdownBody, styles.body)}>
            {options.map((x, index) => (
              <div
                className={cn(classDropdownOption, styles.option, {
                  [styles.selectioned]: x === value,
                })}
                onClick={() => handleClick(x, index)}
                key={index}
              >
                <img
                  src={"/states/" + x + ".png"}
                  style={{ width: "30px", marginRight: "10px" }}
                ></img>

                {x}
              </div>
            ))}
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default SearchDropdown;
