import React from "react";
import styles from "./StatusBanner.module.sass";
import spacing from "../../styles/spacing.module.sass";
import {
  FaInfoCircle,
  FaCheckCircle,
  FaExclamationCircle,
  FaExclamationTriangle,
} from "react-icons/fa";

function StatusBanner({ text, statusType }) {
  if (statusType === "success") {
    return (
      <div className={styles.success}>
        <FaCheckCircle fontSize={20} />
        <span className={spacing.ml10}>{text}</span>
      </div>
    );
  } else if (statusType === "warning") {
    return (
      <div className={styles.warning}>
        <FaExclamationTriangle fontSize={20} />
        <span className={spacing.ml10}>{text}</span>
      </div>
    );
  } else if (statusType === "info") {
    return (
      <div className={styles.warning}>
        <FaInfoCircle fontSize={20} />
        <span className={spacing.ml10}>{text}</span>
      </div>
    );
  } else {
    return (
      <div className={styles.error}>
        <FaExclamationCircle fontSize={20} />
        <span className={spacing.ml10}>{text}</span>
      </div>
    );
  }
}

export default StatusBanner;
