import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./VehicleDetails.module.sass";
import { Link } from "react-router-dom";
import Icon from "../../../components/Icon";

function VehicleDetails({ regoComprehensiveData }) {
  useEffect(() => {
    // buildVehicleDetails();
  }, []);

  function formatString(inputString) {
    const characters = inputString.split("");
    const firstCapitalIndex = characters.findIndex(
      (char) => char === char.toUpperCase()
    );

    // If a capital letter is found, insert a space before it
    if (firstCapitalIndex !== -1) {
      characters.splice(firstCapitalIndex, 0, " ");
    }

    // Join the characters back into a string
    const formattedBodyType = characters.join("");

    // Capitalize the first letter of the formatted string
    const capitalizedBodyType =
      formattedBodyType.charAt(0).toUpperCase() + formattedBodyType.slice(1);

    return capitalizedBodyType;
  }

  function buildVehicleDetails() {
    let keys = Object.keys(regoComprehensiveData);
    var itemList = [];

    for (let i = 0; i < keys.length; i++) {
      if (
        regoComprehensiveData[keys[i]] &&
        typeof regoComprehensiveData[keys[i]] === "string" &&
        keys[i] !== "govRegoLink"
      ) {
        itemList.push(
          <div className={styles.line} key={i}>
            <div className={styles.subtitle}>{formatString(keys[i])}</div>
            <div className={styles.details}>
              <div className={styles.content}>
                {regoComprehensiveData[keys[i]]}
              </div>
            </div>
          </div>
        );
      }
    }

    return itemList;
  }

  return (
    <div className={styles.body}>
      <div className={styles.item}>
        <div className={styles.maintitle}>
          Vehicle report for <span>{regoComprehensiveData.regoNumber}</span> in
          <span style={{ marginLeft: "8px" }}>
            {regoComprehensiveData.regoState}
          </span>
        </div>
        <div className={styles.info}>Registration Details</div>
        <div className={styles.list}>{buildVehicleDetails()}</div>
      </div>
    </div>
  );
}

export default VehicleDetails;
