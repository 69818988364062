import React, { useEffect, useState } from "react";
import Profile from "../../components/Profile";
import LoadingState from "./LoadingState";
import FoundRegoData from "./FoundRegoData";
import NotFound from "./NotFound";
import cn from "classnames";
import styles from "./RegoSearch.module.sass";
import Loader from "../../components/Loader";
import Utils from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import Network from "../../utils/network";
import { RegoSummary } from "../../models/rego_summary";
import { loadStripe } from "@stripe/stripe-js";
import { Payment } from "../../models/payment";
import Divider from "../../components/Divider";

const ausStates = ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"];

function RegoSearch() {
  const navigate = useNavigate();
  const [loadingList, setLoadingList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [regoFound, setRegoFound] = useState();
  const [curRego, setCurRego] = useState();
  const [regoSummaryObj, setRegoSummaryObj] = useState();
  const [curState, setCurState] = useState();
  const [paymentData, setPaymentData] = useState();
  const [flowuid, setFlowuid] = useState();
  const [stripePromise, setStripePromise] = useState();
  const [clientSecret, setClientSecret] = useState();

  useEffect(() => {
    if (!Utils.getUrlPram("state") || !Utils.getUrlPram("rego")) {
      navigate("/");
    } else if (!ausStates.includes(Utils.getUrlPram("state"))) {
      navigate("/");
    } else {
      setCurState(Utils.getUrlPram("state"));
      setCurRego(Utils.getUrlPram("rego").trim().toUpperCase());
      getRegoDetails();
    }
  }, []);

  function getRegoDetails() {
    var data = {
      state: Utils.getUrlPram("state"),
      rego: Utils.getUrlPram("rego").trim().toUpperCase(),
    };

    if (Utils.getStorageItem("referrer")) {
      data.referrer = Utils.getStorageItem("referrer");
    }

    Network.makeCall({
      endpoint: "getRegoDetails",
      data: data,
    }).then((response) => {
      if (response.success === true) {
        setRegoFound(true);

        setInterval(() => {
          setLoadingList((prevList) => [...prevList, true]);
        }, 1000);

        const regoData = RegoSummary.fromJSON(
          JSON.stringify(response.regoSummary)
        );
        const paymentData = Payment.fromJSON(
          JSON.stringify(response.paymentObj)
        );
        setFlowuid(response.flowuid);
        setStripePromise(loadStripe(paymentData.publishableKey));
        setClientSecret(paymentData.clientSecret);
        setPaymentData(paymentData);
        setRegoSummaryObj(regoData);

        // Add a delay to show the loading state
      } else {
        setInterval(() => {
          setLoadingList([...loadingList, false]);
        }, 1000);
        setRegoFound(false);
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 20000);
      // setTimeout(() => {
      //   setIsLoading(false);
      // }, 20000);
    });
  }

  if (isLoading) {
    return (
      <LoadingState
        rego={curRego}
        state={curState}
        regoFound={regoFound}
        loadingList={loadingList}
      />
    );
  } else if (regoFound == true && stripePromise) {
    return (
      <FoundRegoData
        flowuid={flowuid}
        regoSummaryObj={regoSummaryObj}
        stripePromise={stripePromise}
        clientSecret={clientSecret}
        price={paymentData.amount}
      />
    );
  } else if (regoFound == false) {
    return <NotFound regoNumber={curRego} state={curState} />;
  }
}

export default RegoSearch;
